import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[desabilitar]'
})
export class DesabilitarDirective {

	@Input() set desabilitar(condicao: boolean) {
		this._disabled(condicao);
	}
	field: Element;

	constructor(private element: ElementRef) {
		this.field = this.element.nativeElement;
		this._disabled(true);
	}

	_disabled(condicao: boolean) {
		if (condicao)
			this.field.setAttribute('disabled', '');
		else
			this.field.removeAttribute('disabled');
	}

}
