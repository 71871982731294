import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[selecionar]'
})
export class SelecionarDirective implements OnInit {

	condicao: boolean;
	@Input() set selecionar(condicao: boolean) {
		this.condicao = condicao;
		this._checked();
	}
	field: Element;

	constructor(private element: ElementRef) {
		this.field = this.element.nativeElement;
	}

	ngOnInit(): void {
		if (this.condicao === undefined)
			this.condicao = true;
		this._checked();
	}

	_checked() {
		if (this.condicao)
			this.field.setAttribute('checked', '');
		else
			this.field.removeAttribute('checked');
	}

}
