import { RequestService } from './app.request.services';
import { Observable } from "rxjs";
import { Params } from "../interfaces/app.request.interface";
import { Injectable } from '@angular/core';
import { Paginacao } from '../model/app.paginacao.model';

@Injectable()
export class RequestAspNetAPIService {

	constructor(private request:RequestService ) {
	}

	public Get<T>(url: string, params: Params = null): Observable<T> {
		return this.request.Get(url,params)
					.pipeResponseAspNetAPI();
	}

	public GetPaginado<T>(url: string, params: Params = null): Observable<Paginacao<T>> {
		return this.request.Get(url,params)
					.pipeResponsePaginadoAspNetAPI();
	}

	public Post<T>(url: string, params: Params): Observable<T> {
		return this.request.Post(url,params)
					.pipeResponseAspNetAPI();
	}

	public Put<T>(url: string, params: Params): Observable<T> {
		return this.request.Put(url,params)
					.pipeResponseAspNetAPI();
	}

	public Patch<T>(url: string, params: Params = null): Observable<T> {
		return this.request.Patch(url,params)
					.pipeResponseAspNetAPI();
	}

	public Delete<T>(url: string, params: Params): Observable<T> {
		return this.request.Delete(url,params)
					.pipeResponseAspNetAPI();
	}
}
