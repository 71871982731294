import { Directive } from "@angular/core";
import { NG_VALIDATORS, UntypedFormControl } from "@angular/forms";

@Directive({
    selector: '[appCpfCnpjValidacao]',
    providers: [
        { provide: NG_VALIDATORS, useValue: 'appCpfCnpjValidacao', multi: true }
    ]
})
export class InscricaoValidacaoDirective {

    public static validarInscricao(_inscricao: UntypedFormControl): any {

        if (_inscricao == null) {
            return null;
        }

        if (_inscricao.value == null) {
            return null;
        }

        const inscricao: string = _inscricao.value;
        if (inscricao.length === 9) { // ie ou is
            const primeirosDigitos = Number(inscricao.slice(0, 2));
            if (primeirosDigitos === 28 || primeirosDigitos === 82) {
                return InscricaoValidacaoDirective.validarIEIS(_inscricao);
            } else {
                return { 'inscricaoInvalida': true };
            }
        } else if (inscricao.length === 11) {  // cpf
            return InscricaoValidacaoDirective.validarCpf(_inscricao);
        } else if (inscricao.length === 14) { // cnpj
            return InscricaoValidacaoDirective.validarCnpj(_inscricao);
        }

        return { 'inscricaoInvalida': true };
    }

    public static validarCpfCnpj(_cpfCnpj: UntypedFormControl): any {

        if (_cpfCnpj == null) {
            return null;
        }

        if (_cpfCnpj.value == null) {
            return null;
        }

        const cpfCnpj: string = _cpfCnpj.value;
        if (cpfCnpj.length === 11)  // cpf
            return InscricaoValidacaoDirective.validarCpf(_cpfCnpj);
        else if (cpfCnpj.length === 14)  // cnpj
            return InscricaoValidacaoDirective.validarCnpj(_cpfCnpj);
        else
            return { 'inscricaoInvalida': true };

    }

    public static validarCpf(_cpf: UntypedFormControl): any {

        if (_cpf == null) {
            return null;
        }

        if (_cpf.value == null) {
            return null;
        }

        const cpf: string = _cpf.value;

        if (cpf.length !== 11) {
            return { 'inscricaoInvalida': true };
        }

        let soma = 0;
        let resto = 0;

        for (let i = 1; i <= 9; i++) {
            soma += Number(cpf.slice(i - 1, i)) * (11 - i);
        }

        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) {
            resto = 0;
        }

        if (resto !== Number(cpf.slice(9, 10))) {
            return { 'inscricaoInvalida': true };
        }

        soma = 0;

        for (let j = 1; j <= 10; j++) {
            soma += Number(cpf.slice(j - 1, j)) * (12 - j);
        }

        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) {
            resto = 0;
        }

        if (resto !== Number(cpf.slice(10, 11))) {
            return { 'inscricaoInvalida': true };
        }

        return null;
    }

    public static validarCnpj(_cnpj: UntypedFormControl): any {

        if (_cnpj == null) {
            return null;
        }

        if (_cnpj.value == null) {
            return null;
        }

        const cnpj: string = _cnpj.value;

        if (cnpj.length !== 14) {
            return { 'inscricaoInvalida': true };
        }

        let tamanho = cnpj.length - 2;
        let numeros = cnpj.slice(0, tamanho);
        const digitos = cnpj.slice(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        let resultado;

        for (let i = tamanho; i >= 1; i--) {
            soma += Number(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== Number(digitos.charAt(0))) {
            return { 'inscricaoInvalida': true };
        }

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += Number(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

        if (resultado !== Number(digitos.charAt(1))) {
            return { 'inscricaoInvalida': true };
        }

        return null;
    }

    public static validarIEIS(_ie: UntypedFormControl): any {

        if (_ie == null) {
            return null;
        }

        if (_ie.value == null) {
            return null;
        }

        const ie: string = _ie.value;


        if (ie.length !== 9) {// ie ou is
            return { 'inscricaoInvalida': true };
        }

        const primeirosDigitos = Number(ie.slice(0, 2));

        if (primeirosDigitos !== 28 && primeirosDigitos !== 82) {
            return { 'inscricaoInvalida': true };
        }

        const multiplicador = 9;
        let soma = 0;
        for (let i = 0; i <= 7; i++) {
            soma += Number(ie.charAt(i)) * (multiplicador - i);
        }

        const resto = soma % 11;
        let dig = 0;
        if (resto !== 0) {
            dig = 11 - resto;
        }

        if (dig > 9) {
            dig = 0;
        }
        const resultado = dig !== Number(ie.charAt(8));
        if (resultado) {
            return { 'inscricaoInvalida': true };
        }

        return null;
    }
}
