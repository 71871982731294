import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApenasNumerosDirective } from './app.apenasnumeros.directive';
import { DesabilitarDirective } from './app.desabilitar.directive';
import { InscricaoValidacaoDirective } from './app.inscricao.validacao.directive';
import { ObrigatorioDirective } from './app.obrigatorio.directive';
import { SelecionarDirective } from './app.selecionar.directive';
import { NumeroEnderecoDirective } from './app.numeroendereco.directive';
import { MaskCpfCnpjDirective } from './app.maskcpfcnpj.directive';

@NgModule({
	declarations: [
		ApenasNumerosDirective,
		DesabilitarDirective,
		InscricaoValidacaoDirective,
		ObrigatorioDirective,
		SelecionarDirective,
		NumeroEnderecoDirective,
		MaskCpfCnpjDirective
	],
	imports: [
		CommonModule
	],
	exports: [
		ApenasNumerosDirective,
		DesabilitarDirective,
		InscricaoValidacaoDirective,
		ObrigatorioDirective,
		SelecionarDirective,
		NumeroEnderecoDirective,
		MaskCpfCnpjDirective
	]
})

export class DirectiveModule { }
