import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { filter, map, Observable } from 'rxjs';
import { EStatusCodeAspNetAPI } from './app/constantes/app.estatuscode.const';
import { Paginacao } from './app/model/app.paginacao.model';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, {
	providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }]
})
	.catch(err => console.error(err));

declare global {
	interface String {
		toFloat: (this: String) => Number;
		getMask: (this: String) => String;
		coordiantesInDecimal: () => number;
		camelCase: () => String;
	}
	interface StringConstructor {
		isNullOrEmpty: (value: any) => boolean;
	}

	interface Array<T> {
		orderBy: (ordenador: string, desc?: boolean) => void;
		distinct: () => Array<T>;
		any: () => boolean;
		first: () => T
		last: () => T
	}
	interface Number {
		toStartFixed: (casas: number, caracter: string, comma?: boolean) => string;
		toFixedView: (casas: number) => string;
	}

	interface Element {
		setAttributes: (attrs: any) => void
	}
}

Array.prototype.distinct = function<T>(): Array<T> {
	const lista = this;
	return lista
		.map((item: T) => JSON.stringify(item))
		.filter((item: T, posicao: number, array: Array<T>) => array.indexOf(item) === posicao)
		.map((item: string) => JSON.parse(item));
}

Array.prototype.any = function(): boolean {
	return this.length > 0;
}

Array.prototype.first = function<T>(): T {
    return this.find((_: any, index: number) => index === 0);
}

Array.prototype.last = function<T>(): T {
    const ultimo = this.length - 1;
    return this.find((_: any, index: number) => index === ultimo);
}

String.prototype.toFloat = function (this: String): Number {
	return +this?.replace(/,/g, '.');
}

String.prototype.camelCase = function(this: String): string{
	return this.toLowerCase().replace(/(^|\s)[a-z](?=[A-Za-zÀ-ÖØ-öø-ÿ]{2})/g, function (letter) {
		return letter.toUpperCase();
	});
}

String.isNullOrEmpty = function (value: any): boolean {
	return value === null || value === undefined || value === '';
}

String.prototype.getMask = function (): String {
	const number = this.replace(/\D/g, '');
	return number.length == 9 ? "00000-0000" : "0000-0000";
}

Number.prototype.toStartFixed = function (casas: number, caracter: string, comma: boolean = false): string {
	const number = parseInt(this) + '';
	let numberReverse = this.toString().split('').reverse().join('');
	for (let i = number.length; i < casas; i++)
		numberReverse += caracter;
	if (comma)
		numberReverse = numberReverse.replace('.', ',');
	return numberReverse.split('').reverse().join('');
}

Number.prototype.toFixedView = function (casas: number): string {
	return this.toFixed(casas)
		.split('').reverse().join('')
		.replace(/\./, ',')
		.split('').reverse().join('');
}

Array.prototype.orderBy = function (ordenador: string, desc: boolean = false): void {
	//bubbleSort
	let trocado: boolean;
	do {
		trocado = false;
		for (let i = 0; i < this.length - 1; i++) {
			let valorAtual = typeof this[i][ordenador] === "function" ? this[i][ordenador]() : this[i][ordenador];
			let valorSeguinte = typeof this[i + 1][ordenador] === "function" ? this[i + 1][ordenador]() : this[i + 1][ordenador];
			if ((valorAtual < valorSeguinte && desc) ||
				(valorAtual > valorSeguinte && !desc)) {
				let temp = this[i];
				this[i] = this[i + 1];
				this[i + 1] = temp;
				trocado = true;
			}
		}
	} while (trocado);
};


String.prototype.coordiantesInDecimal = function (): number {
	const tipoCoordenda = this.replace(/[^A-Za-z]/g, "");
	const [, grau, minuto, segundo] = this.split(" ");
	const minutos = parseInt(grau.replace(/\D/g, ""));
	const segundos1 = parseInt(minuto.replace(/[\'\"]/g, "")) * 60;
	const segundos2 = parseFloat(segundo.replace(/[\'\"]/g, "").replace(",", "."));
	const ehNegativo = tipoCoordenda == "S" || tipoCoordenda == "W";
	let numero = minutos + (segundos1 + segundos2) / 3600;

	if (ehNegativo)
		numero *= -1;

	return numero;
}

if (!(Element.prototype.setAttributes instanceof Function)) {
	Element.prototype.setAttributes = function (attrs: any) {
		for (var idx in attrs) {
			if ((idx == 'styles' || idx == 'style') && typeof attrs[idx] == 'object') {
				for (var prop in attrs[idx]) { this.style[prop] = attrs[idx][prop] }
			} else if (idx == 'html') {
				this.innerHTML = attrs[idx];
			} else {
				this.setAttribute(idx, attrs[idx]);
			}
		}
	};
}

declare module 'rxjs/internal/Observable' {
	interface Observable<T>{
		pipeResponseAspNetAPI: (this:Observable<T>) => Observable<T>
		pipeResponsePaginadoAspNetAPI: (this:Observable<T>) => Observable<Paginacao<T>>
	}
}

Observable.prototype.pipeResponseAspNetAPI = function<T>(this:Observable<T>):Observable<T>{
	return this.pipe(
					filter(resp => !!resp),
					map((retorno: any) => {
						const { statusCode, errors, data } = retorno;
							if(statusCode == EStatusCodeAspNetAPI.OK)
								return data as T;

							throw errors;
				}),

	);
}

Observable.prototype.pipeResponsePaginadoAspNetAPI = function<T>(this:Observable<T>):Observable<Paginacao<T>>{
	return this.pipe(
					filter(resp => !!resp),
					map((retorno: any) => {
						const { statusCode, errors, data, pagination } = retorno;
							if(statusCode == EStatusCodeAspNetAPI.OK)
								return new Paginacao(data as T[], pagination.paginaAtual, pagination.totalRegistros );

							throw errors;
				}),

	);
}
