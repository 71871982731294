import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, retry, map } from 'rxjs/operators';

import { environment } from "src/environments/environment";

export class Token {
	access_token: string;
	token_type: string;
	expires_in: number;
}

@Injectable()
export class AuthenticationProvider {
	private authUrl: string = environment.apiUrl + 'auth/token';
	private token: Token;

	constructor(private http: HttpClient) {
	}

	getTokenObs(): Observable<Token> {
		if (this.token)
			return of(this.token);

		const key = environment.chave;
		const person = 'pessoa=03980919000187';
		const grantType = 'grant_type=password';
		const data = `${key}&${person}&${grantType}`;
		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
			'Access-Control-Allow-Origin': '*'
		});

		return this.http.post<Token>(this.authUrl, data, { headers: headers })
			.pipe(
				retry(2),
				map((token: Token) => token),
				tap(token => {
					this.token = token;
					setTimeout(() => { this.token = null }, this.token.expires_in * 1000);
				})
			)
	}

	getToken(): Promise<Token> {
		if (!environment.auth) {
			return Promise.resolve(new Token());
		}
		if (this.token) {
			return Promise.resolve(this.token);
		}
		return this.getTokenObs().toPromise();
	}

	private handleSuccess(resp: any): Promise<Token> {
		this.token = resp as any;
		setTimeout(() => { this.token = null }, this.token.expires_in * 1000);
		return Promise.resolve(this.token);
	}

	private handleError(error: any): Promise<any> {
		console.error('Auth problem: ', error);
		return Promise.reject(error.message || error);
	}
}
