import { Component } from '@angular/core';
import { environment } from "src/environments/environment";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent {

	public isNavbarCollapsed = true;
	public descricaoTopo = 'Serviços Públicos';
	public descricaoMeio = 'Agência Estadual de Defesa Sanitária Animal e Vegetal';
	public descricaoBaixo = 'Governo do Estado do Mato Grosso do Sul';
	public esaniagroUrl = environment.esaniagroUrl;
	public ciadeUrl = environment.ciadeUrl;
	public consultaDaemsUrl = environment.consultaDaemsUrl;

	constructor() { }

}
