import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthenticationProvider } from '../auth/app.auth.token';
import { Rotas } from '../helper/app.rotas.utils'
import { Parametro } from '../model/app.parametro.model'
import { catchError, filter, map, pluck } from 'rxjs/operators';
import { CoordenadaMunicipio } from '../model/app.coordenada.model';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class CoordenadaService {
	private _baseUrl: string = "https://www.pinms.ms.gov.br/arcgis/rest/services/AGRAER_SERVICOS/Estado_de_MS_Politico/MapServer/0/query"

	constructor(private http: HttpClient, private auth: AuthenticationProvider, private rotas: Rotas) {
	}

	getMunicipioPorCoordenada(latitude: number, longitude: number): Promise<number> {

		const options = {
			headers: new HttpHeaders(
				{
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Headers': 'Content-Type, Accept, Access-Control-Request-Origin, Referer'
				})
		};

		var parametros = new Array<Parametro>();
		parametros.push(new Parametro('geometry', `${longitude}%2C${latitude}`));
		parametros.push(new Parametro('geometryType', 'esriGeometryPoint'));
		parametros.push(new Parametro('spatialRel', 'esriSpatialRelIntersects'));
		parametros.push(new Parametro('outFields', 'Municipio%2CGeocodigo'));
		parametros.push(new Parametro('returnGeometry', false));
		parametros.push(new Parametro('returnDistinctValues', false));
		parametros.push(new Parametro('returnIdsOnly', false));
		parametros.push(new Parametro('returnCountOnly', false));
		parametros.push(new Parametro('returnExtentOnly', false));
		parametros.push(new Parametro('returnZ', false));
		parametros.push(new Parametro('returnM', false));
		parametros.push(new Parametro('f', 'json'));

		var url = this.rotas.adicionarParametros(this._baseUrl, parametros);
		return firstValueFrom(this.http.get(url, options)
			.pipe(
				pluck('features'),
				filter((x: any[]) => {
					return x.length > 0
				}),
				map((data: any) => {
					return data.at(0);
				}),
				pluck('Geocodigo'),
				map((geocodigo: string) => parseInt(geocodigo))
			));
	}
}
