import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';

import { AuthenticationProvider, Token } from '../auth/app.auth.token';
import { Rotas } from '../helper/app.rotas.utils'
import { Parametro } from '../model/app.parametro.model'
import { DtoPacote } from '../model/retorno/app.dtopacote.model';
import { Anexo } from '../model/app.anexo.model';
import { map, filter, take, switchMap, pluck, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { TipoCoordenada } from '../model/app.tipocoordenada.model';
import { Historico } from '../model/app.historicohabilitacaoficha.model';
import { Pais } from '../model/app.pais.model';

@Injectable()
export class FichaService {

	constructor(private http: HttpClient, private auth: AuthenticationProvider, private rotas: Rotas) {
	}

	getFichas(ieCpfCnpj: string, cpfCnpj: string, codPGA: string): Promise<any[]> {
		return this.auth.getToken().then(token => {

			let autorization = token.token_type + ' ' + token.access_token;
			const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': autorization }) };

			var parametros = new Array<Parametro>();
			parametros.push(new Parametro('IECPFCNPJ', ieCpfCnpj));
			parametros.push(new Parametro('CPFCNPJ', cpfCnpj));
			parametros.push(new Parametro('PGA', codPGA));

			var url = this.rotas.adicionarParametros(this.rotas.ficha(), parametros);

			return this.http.get(url, options)
				.toPromise()
				.then(resp => (resp as DtoPacote).data as any[])
				.catch(this.handleError);
		})
	}

	getContribuinteCCE(ieCpfCnpj: string): Promise<any> {
		return this.auth.getToken().then(token => {

			let autorization = token.token_type + ' ' + token.access_token;
			const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': autorization }) };

			var parametros = new Array<Parametro>();
			parametros.push(new Parametro('ieCpfCnpj', ieCpfCnpj));

			var url = this.rotas.adicionarParametros(this.rotas.contribuinteCCE(), parametros);

			return this.http.get(url, options)
				.toPromise()
				.then(resp => (resp as DtoPacote) as any)
				.catch(this.handleError);
		})
	}

	ObterTiposCoordenadas(): Promise<TipoCoordenada[]> {
		return this.auth.getToken().then(token => {

			let autorization = token.token_type + ' ' + token.access_token;
			const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': autorization }) };

			var url = this.rotas.ObterTiposCoordenadas();

			return this.http.get(url, options)
				.toPromise()
				.then(resp => (resp as DtoPacote).data as TipoCoordenada[])
				.catch(this.handleError);
		})
	}

	ObterHistoricoHabilitacaoObs(inscricaoEstadual: string): Observable<Historico[]> {
		return this.auth.getTokenObs()
			.pipe(
				switchMap(token => {
					var parametros = new Array<Parametro>();
					parametros.push(new Parametro('inscricao', inscricaoEstadual));
					var url = this.rotas.adicionarParametros(this.rotas.obterHistoricoHabilitacaoFicha(), parametros);

					return this._get<Historico[]>(url, token)
						.pipe(map(historicos => historicos ?? []));
				})
			)
	}

	ObterPaisesObs(): Observable<Pais[]> {
		return this.auth.getTokenObs()
			.pipe(
				switchMap(token => {
					var url = this.rotas.ObterPaises();
					return this._get<Pais[]>(url, token)
						.pipe(map(paises => paises ?? []));
				})
			)
	}

	ObterMarcaRebanho(ieCpfCnpj: string): Observable<any> {
		return this.auth.getTokenObs()
			.pipe(
				switchMap(token => {
					const parametros = new Array<Parametro>();
					parametros.push(new Parametro('ieCpfCnpj', ieCpfCnpj));
					const url = this.rotas.adicionarParametros(this.rotas.ObterFichaSanitariaMarcaRebanho(), parametros);

					return this._get<string>(url, token);
				})
			)
	}

	ObterRelacaoFichaSanianitariaAnexo(ieCpfCnpj: string): Observable<Anexo[]> {
		return this.auth.getTokenObs()
			.pipe(
				switchMap(token => {
					const parametros = new Array<Parametro>();
					parametros.push(new Parametro('ieCpfCnpj', ieCpfCnpj));
					const url = this.rotas.adicionarParametros(this.rotas.ObterRelacaoFichaSanianitariaAnexo(), parametros);

					return this._get<Anexo[]>(url, token)
						.pipe(map(anexos => anexos ?? []));
				})
			)
	}

	VerificaCoordenadasSiriema(latitude: number, longitude: number, municipioID: number): Promise<any> {
		return this.auth.getToken().then(token => {

			let autorization = token.token_type + ' ' + token.access_token;
			const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': autorization }) };

			var parametros = new Array<Parametro>();
			parametros.push(new Parametro('latitude', latitude));
			parametros.push(new Parametro('longitude', longitude));
			parametros.push(new Parametro('geocode', municipioID));

			var url = this.rotas.adicionarParametros(this.rotas.VerificaCoordenadasSiriema(), parametros);

			return this.http.get(url, options)
				.toPromise()
				.then(resp => (resp as DtoPacote) as any);
		})
	}

	gerarCodigoAcesso(inscricaoEstadual: string): Promise<any> {
		return this.auth.getToken().then(token => {

			let autorization = token.token_type + ' ' + token.access_token;
			const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': autorization }) };

			var parametros = new Array<Parametro>();
			parametros.push(new Parametro('inscricao', inscricaoEstadual));

			var url = this.rotas.adicionarParametros(this.rotas.gerarCodigoAcesso(), parametros);

			return this.http.get(url, options)
				.toPromise()
				.then(resp => (resp as DtoPacote) as any);
		})
	}

	ValidarCodigoAcesso(inscricaoEstadual: string, codigoAcesso: number): Promise<any> {
		return this.auth.getToken().then(token => {

			let autorization = token.token_type + ' ' + token.access_token;
			const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': autorization }) };

			var parametros = new Array<Parametro>();
			parametros.push(new Parametro('inscricao', inscricaoEstadual));
			parametros.push(new Parametro('codigo', codigoAcesso));

			var url = this.rotas.adicionarParametros(this.rotas.ValidarCodigoAcesso(), parametros);

			return this.http.get(url, options)
				.toPromise()
				.then(resp => (resp as DtoPacote) as any)
				.catch(error => error);
		})
	}

	ReenviarCodigoAcesso(inscricaoEstadual: string): Promise<any> {
		return this.auth.getToken().then(token => {

			let autorization = token.token_type + ' ' + token.access_token;
			const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': autorization }) };

			var parametros = new Array<Parametro>();
			parametros.push(new Parametro('IE', inscricaoEstadual));

			var url = this.rotas.adicionarParametros(this.rotas.ReenviarCodigoAcesso(), parametros);

			return this.http.get(url, options)
				.toPromise()
				.then(resp => (resp as DtoPacote) as any);
		})
	}

	UploadAnexosAsync(anexo: Anexo) {
		return this.auth.getToken().then(token => {
			let autorization = token.token_type + ' ' + token.access_token;
			const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': autorization }) };

			var url = this.rotas.TransmitirFichaSanitariaDocumento();
			return this.http.post(url, anexo, options)
				.toPromise()
				.then(resp => (resp as DtoPacote) as any);
		})
	}

	UploadAnexos(parametros: any): Observable<string> {
		var url = this.rotas.TransmitirFichaSanitariaDocumento();
		return this._post<string>(url, parametros);
	}

	UploadMarcaRebanho(parametros: any): Observable<number> {
		var url = this.rotas.TransmitirFichaSanitariaMarcaRebanho();
		return this._post<number>(url, parametros);
	}

	InserirFichaSanitaria(parametros: any): Observable<number> {
		const url = this.rotas.InserirFichaSanitaria();
		return this._post<number>(url, parametros);
	}

	private _post<T>(url: string, parametros: any): Observable<T> {
		return this.auth.getTokenObs()
			.pipe(
				filter(token => token != null),
				take(1),
				switchMap(({ token_type, access_token }: Token) => {
					const autorization = `${token_type} ${access_token}`;
					const options = {
						headers: new HttpHeaders({
							'Content-Type': 'application/json',
							'Authorization': autorization,
							'Access-Control-Allow-Origin': '*'
						})
					};
					return this.http.post(url, parametros, options)
						.pipe(
							map(({ status, data }: any) => {
								const { codigo, mensagem } = status;
								if (codigo != HttpStatusCode.Ok)
									throw new Error(mensagem);
								return data;
							})
						);
				})
			);
	}

	private handleError(error: any): Promise<any> {
		console.error('Um erro ocorreu', error);
		return Promise.reject(error.message || error);
	}

	private _get<T>(url: string, { token_type, access_token }: Token): Observable<T | any> {
		const dados: Subject<T | any> = new BehaviorSubject<T | any>(null);
		const autorization = `${token_type} ${access_token}`;

		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'Authorization': autorization,
				'Access-Control-Allow-Origin': '*'
			})
		};

		this.http.get(url, options)
			.pipe(
				pluck('data')
			)
			.subscribe({
				next: (data: T) => dados.next(data),
				error: ({ error, message }) => {
					console.log("ERROR", error.message || message);
					dados.next(null);
				}
			});

		return dados.asObservable();
	}
}
