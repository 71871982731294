import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
	selector: '[obrigatorio]'
})
export class ObrigatorioDirective implements OnInit {

	condicao: boolean;
	@Input() set obrigatorio(condicao: boolean) {
		this.condicao = condicao;
		this._write();
	};
	field: Element;
	span: HTMLSpanElement;

	constructor(private element: ElementRef) {
		this.field = this.element.nativeElement;
		this.span = document.createElement('span');
		this.span.classList.add('text-danger');
		this.span.innerHTML = "&nbsp;*";
		this.span.style.fontWeight = '700';
	}

	ngOnInit(): void {
		if (this.condicao === undefined)
			this.condicao = true;
		this._write();
	}

	_write(): void {
		if (this.condicao) {
			this.field.after(this.span);
		} else
			this.span?.remove();
	}

}
