import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpStatusCode } from "@angular/common/http";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { catchError, filter, map, pluck, retry, switchMap, tap } from "rxjs/operators";
import { Params } from "../interfaces/app.request.interface";
import { Token } from "../model/app.token.model";
import { Injectable } from '@angular/core';

@Injectable()
export class RESTService {
	private token: Token;

	constructor(private http: HttpClient) {
	}

	public Get<T>(url: string, params: Params = null, ehBlob: boolean = false): Observable<T> {
		const dados: Subject<T> = new BehaviorSubject<T>(null);
		this._token()
			.pipe(
				switchMap((token: Token) => {
					const { token_type, access_token } = token;
					const autorization = `${token_type} ${access_token}`;
					const headers = new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Authorization': autorization
					});

					let responseType: any = "json";
					if (ehBlob)
						responseType = "blob";

					return this.http.get(url, { headers, params, responseType })
						.pipe(
							filter(resposta => !!resposta),
							map((retorno: any) => {
								if (!ehBlob) {
									const { status, errors, data } = retorno;
									const { codigo } = status;
									if (codigo != HttpStatusCode.Ok)
										throw new Error(errors);
									return data as T;
								}
								return retorno as T;
							})
						);
				})
			)
			.subscribe((data: T) => {
				dados.next(data);
				dados.complete();
			});
		return dados.asObservable();
	}

	public Post<T>(url: string, params: Params, ehBlob: boolean = false): Observable<any> {
		return this._token()
			.pipe(
				switchMap((token: Token) => {
					const { token_type, access_token } = token;
					const autorization = `${token_type} ${access_token}`;
					const headers = new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Authorization': autorization
					});

					let responseType: "json" = "json";
					if (ehBlob)
						responseType = "blob" as "json";

					return this.http.post(url, params, { headers, responseType })
						.pipe(
							map((retorno: any) => {
								if (!ehBlob) {
									const { status, errors, data } = retorno;
									const { codigo, mensagem } = status;
									if (codigo != HttpStatusCode.Ok)
										throw new Error(errors);
									if (codigo === HttpStatusCode.Ok && !data)
										return status;
									return data as T;
								}
								return retorno;
							})
						);
				})
			);
	}

	public Put<T>(url: string, params: Params): Observable<any> {
		return this._token()
			.pipe(
				switchMap((token: Token) => {
					const { token_type, access_token } = token;
					const autorization = `${token_type} ${access_token}`;
					const headers = new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Authorization': autorization
					});
					return this.http.put(url, params, { headers, responseType: "json" })
						.pipe(
							map((retorno: any) => {
								const { status, errors, data } = retorno;
								const { codigo } = status;
								if (codigo != HttpStatusCode.Ok)
									throw new Error(errors);
								return data as T;
							})
						);
				})
			);
	}

	private _token(): Observable<Token> {
		if (this.token)
			return of(this.token);
		const authUrl: string = environment.apiUrl + 'auth/token';
		const chave: string = `${environment.chave}`;
		const person: string = `${environment.pessoa}`;
		const grantType: string = 'grant_type=password';
		const data: string = `${chave}&${person}&${grantType}`;
		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
			'Access-Control-Allow-Origin': '*'
		});

		return this.http.post<Token>(authUrl, data, { headers: headers })
			.pipe(
				retry(2),
				map((token: Token) => token),
				tap((token: Token) => {
					this.token = token;
					setTimeout(() => { this.token = null }, this.token.expires_in * 1000);
				}),
				catchError((erro, souce) => {
					console.log(erro);
					return souce;
				})
			);
	}

	public GetJson<T>(url: string): Observable<T | []> {
		const dados: Subject<T | []> = new BehaviorSubject<T | []>([]);
		this.http.get(url)
			.pipe(
				pluck('data')
			)
			.subscribe((data: T) => dados.next(data));
		return dados.asObservable();
	}
}
