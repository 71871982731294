import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: 'input[numeroEndereco]' })
export class NumeroEnderecoDirective {
    constructor(private _el: ElementRef, private ngControl: NgControl) { }

    @HostListener('input', ['$event'])
    handleInputEvent(input: InputEvent): void {
        let novoValor: string;

        const valor = this._el.nativeElement.value;
        if (valor.length === 1) {
            if (this.isNumber(input.data))
                novoValor = input.data;
            else if (this.isValidCharacter(input.data))
                novoValor = input.data;
        } else {
            if (this.isNumber(input.data) && this.isNumber(valor))
                novoValor = valor;
            else if (this.isValidCharacter(input.data) && this.isValidCharacter(valor))
                novoValor = valor;
            else novoValor = this.grantInitialType();
        }
        if (!!input.data) this._el.nativeElement.value = novoValor.toUpperCase();
        if (this.ngControl?.control && !!input.data)
            this.ngControl.control.setValue(novoValor.toUpperCase());
    }

    private grantInitialType(): string {
        return this._el.nativeElement.value.slice(0, -1);
    }

    private isNumber(valor: string): boolean {
        return /^[0-9]+$/.test(valor);
    }

    private isValidCharacter(valor: string): boolean {
        return /^[SN\/]+$/i.test(valor)
    }
}