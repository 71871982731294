import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { NgControl } from '@angular/forms';

@Directive({
	selector: 'input[apenasNumeros]',
})
export class ApenasNumerosDirective {

	@Input() apenasNumeros: boolean;

	constructor(private _el: ElementRef, private ngControl: NgControl) { }

	@HostListener('input', ['$event']) onInputChange(event) {
		const initalValue = this._el.nativeElement.value;
		const novoValor = initalValue.replace(/[^0-9]*/g, '');
		this._el.nativeElement.value = novoValor
		if(this.ngControl?.control)
			this.ngControl.control.setValue(novoValor);
	}
}
