import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, from, Observable, of, Subject } from "rxjs";
import { catchError, filter, map, pluck, retry, switchMap, tap } from "rxjs/operators";
import { Params } from "../interfaces/app.request.interface";
import { Token } from "../model/app.token.model";
import { Injectable } from '@angular/core';

@Injectable()
export class RequestService {
	private token: Token;

	constructor(private http: HttpClient) {
	}

	public Get(url: string, params: Params = null): Observable<any> {
		const dados: Subject<any> = new BehaviorSubject<any>(null);
		this._token()
			.pipe(
				switchMap((token: Token) => {
					const { token_type, access_token } = token;
					const autorization = `${token_type} ${access_token}`;
					const headers = new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						Authorization: autorization
					});

					let responseType: "json" = "json";

					return this.http.get(url, { headers, responseType })
						.pipe(
							filter(resposta => !!resposta)
						);
				})
			)
			.subscribe({
				next:(data: any) => {
					dados.next(data);
					dados.complete();
				},
				error: (error)=> dados.error(`Ops! Ocorreu um erro. Tente novamente.`)
			});
		return dados.asObservable();
	}

	public Post(url: string, params: Params): Observable<any> {

		return this._token()
			.pipe(
				switchMap((token: Token) => {
					const { token_type, access_token } = token;
					const autorization = `${token_type} ${access_token}`;
					const headers = new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Authorization': autorization
					});

					let responseType: "json" = "json";

					return this.http.post(url, params, { headers, responseType })
						.pipe(
							filter(resp => !!resp)
						);
				})
			);
	}

	public Put(url: string, params: Params): Observable<any> {
		return this._token()
			.pipe(
				switchMap((token: Token) => {
					const { token_type, access_token } = token;
					const autorization = `${token_type} ${access_token}`;
					const headers = new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Authorization': autorization
					});
					return this.http.put(url, params, { headers, responseType: "json" })
						.pipe(
							filter(resp => !!resp)
						);
				})
			);
	}

	public Patch(url: string, params: Params = null): Observable<any> {
		return this._token()
			.pipe(
				switchMap((token: Token) => {
					const { token_type, access_token } = token;
					const autorization = `${token_type} ${access_token}`;
					const headers = new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Authorization': autorization
					});
					return this.http.patch(url, params, { headers, responseType: "json" })
						.pipe(
							filter(resp => !!resp)
						);
				})
			);
	}

	public Delete(url: string, params: Params): Observable<any> {

		return this._token()
			.pipe(
				switchMap((token: Token) => {
					const { token_type, access_token } = token;
					const autorization = `${token_type} ${access_token}`;
					const headers = new HttpHeaders({
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Authorization': autorization
					});

					let responseType: "json" = "json";

					return this.http.delete(url, { headers, params, responseType })
						.pipe(
							filter(resp => !!resp)
						);
				})
			);
	}

	private _token(): Observable<Token> {
		if (this.token) return of(this.token);

		const clientSecret = `client_secret=${environment.clientSecret}`;
		const clientId = `client_id=${environment.clientId}`;
		const grantType = 'grant_type=client_credentials';
		const data = `${grantType}&${clientId}&${clientSecret}`;
		const headers = {
			'content-type': 'application/x-www-form-urlencoded',
			Accept: 'application/json, text/plain, */*',
		};

		return this.http.post<Token>(environment.tokenUrl, data, { headers, responseType: 'json' }).pipe(
			retry(2),
			map((token: Token) => token),
			tap((token: Token) => {
				this.token = token;
				setTimeout(() => { this.token = null; }, this.token.expires_in * 1000);
			}),
			catchError((erro, souce) => {
				console.log(erro);
				return souce;
			})
		);
	}

	public GetJson<T>(url: string): Observable<T | []> {
		const dados: Subject<T | []> = new BehaviorSubject<T | []>([]);
		this.http.get(url)
			.pipe(
				pluck('data')
			)
			.subscribe((data: T) => dados.next(data));
		return dados.asObservable();
	}
}
