<footer>
    <div>
        <div class="container clear">
            <div class="logos">
                <a href="http://www.iagro.ms.gov.br/" target="_blank" style="margin-right: 40px"><img imagePathDirective
                        src="../assets/logo-iagro-rodape.png" /></a>
                <a href="http://www.ms.gov.br/" target="_blank"><img imagePathDirective
                        src="../assets/logo-governo.png" /></a>
            </div>
            <div class="contato">
                <p>
                    <strong>Contato</strong>
                    <i class="fa fa-envelope" aria-hidden="true"></i>E-mail: suporte-iagro@iagro.ms.gov.br<br />
                    <i class="fa fa-phone" aria-hidden="true"></i> Telefone: 0800 647 6713<br />
                    <a class="termoUso"
                        href="http://www.iagro.ms.gov.br/politica-de-privacidade-sistemas-e-banco-de-dados/"
                        target="_blank">Política de privacidade</a><br />
                    <a class="termoUso"
                        href="http://www.iagro.ms.gov.br/termos-e-condicoes-de-uso-sistemas-servicos-e-aplicativos-da-iagro/"
                        target="_blank">Termos e condições de uso</a>
                </p>
            </div>
        </div>
    </div>
    <div>
        <div class="container clear">
            <div class="logos sgi">
                <a href="http://www.sgi.ms.gov.br/" target="_blank"><img imagePathDirective
                        src="../assets/logo-ucqi.png" /></a>
                <a href="http://www.sgi.ms.gov.br/" target="_blank"><img imagePathDirective
                        src="../assets/logo-sgi.png" /></a>
            </div>
        </div>
    </div>
</footer>