import { Component } from '@angular/core';

enum ETipoCard {
	CONSULTA = 1,
	CADASTRO = 2,
	TODOS = 3
}

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html'
})
export class CardComponent {

	public cards: any[];
	filtro:string = '';
	consulta: ETipoCard = ETipoCard.TODOS;
	eTipoCard= ETipoCard;

	constructor() {

		this.cards = [
			{
				id: 1,
				titulo: 'AGROTÓXICO',
				routeLink: '/agrotoxico',
				imagePath: 'assets/icone-agrotoxico.png',
				descricao: 'Consulte os agrotóxicos liberados no estado.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 2,
				titulo: 'ANIMAL IDENTIFICADO',
				routeLink: '/animalIdentificado',
				imagePath: 'assets/icone-animais.png',
				descricao: 'Consulte os Animais Identificados.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 3,
				titulo: 'CADASTRO DE PLANTIO',
				routeLink: '/plantio',
				imagePath: 'assets/icone-plantio.png',
				descricao: 'Cadastro de Plantio.',
				tipo: ETipoCard.CADASTRO,
			},
			{
				id: 4,
				titulo: 'CADASTRO DE TRANSPORTADORES',
				routeLink: '/cadastroTransporte',
				imagePath: 'assets/icone-transporte.png',
				descricao: 'Cadastro de Transportadores de cargas vivas.',
				tipo: ETipoCard.CADASTRO,
			},
			{
				id: 5,
				titulo: 'DOCUMENTO DE TRÂNSITO',
				routeLink: '/gta',
				imagePath: 'assets/icone-caminhao.png',
				descricao: 'Consulte e-GTA/DTA emitidas.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 6,
				titulo: 'DAEMS AVULSA',
				routeLink: '/daems',
				imagePath: 'assets/icone-financeiro.png',
				descricao: 'Emite DAEMS para serviços.',
				tipo: ETipoCard.CADASTRO,
			},
			{
				id: 7,
				titulo: 'ESTABELECIMENTO',
				routeLink: '/estabelecimento',
				imagePath: 'assets/icone-predio.png',
				descricao: 'Consulte os estabelecimentos credenciados.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 8,
				titulo: 'GTS',
				routeLink: '/ecise',
				imagePath: 'assets/icone-ecise.png',
				descricao: 'Consulte Guias de trânsito de subprotudos.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 9,
				titulo: 'EVENTO',
				routeLink: '/eventos',
				imagePath: 'assets/icone-calendario.png',
				descricao: 'Consulte os eventos por município.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 10,
				titulo: 'EXAMES',
				routeLink: '/exame',
				imagePath: 'assets/icone-laudo-exame-requisicao.png',
				descricao: 'Consulte Requisições e Relatórios de Ensaio.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 11,
				titulo: 'FICHA SANITÁRIA',
				routeLink: '/ficha',
				imagePath: 'assets/icone-ficha.png',
				descricao: 'Consulte as informações de fichas habilitadas.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 12,
				titulo: 'INFORMAÇÕES OFICIAIS',
				routeLink: '/docoficiais',
				imagePath: 'assets/icone-documento.png',
				descricao: 'Consulte as Informações Oficiais.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 13,
				titulo: 'LABORATÓRIO',
				routeLink: '/laboratorio',
				imagePath: 'assets/icone-laboratorio.png',
				descricao: 'Consulte o trâmite da sua solicitação laboratorial.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 14,
				titulo: 'NÚCLEO',
				routeLink: '/nucleo',
				imagePath: 'assets/icone-nucleo.png',
				descricao: 'Consulte Núcleos de aves e suínos habilitados em MS.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 15,
				titulo: 'PRODUTO',
				routeLink: '/vacinas',
				imagePath: 'assets/icone-vacina.png',
				descricao: 'Consulte a disponibilidade de antígenos e vacinas.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 16,
				titulo: 'PROFISSIONAL',
				routeLink: '/colaboradores',
				imagePath: 'assets/icone-colaborador.png',
				descricao: 'Consulte Profissionais Habilitados.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 17,
				titulo: 'PTV',
				routeLink: '/ptv',
				imagePath: 'assets/icone-ptv.png',
				descricao: 'Consulte Permissões de Trânsito Vegetal emitidas.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 18,
				titulo: 'CADASTRO DO PRODUTOR',
				routeLink: '/cadastroFicha',
				imagePath: 'assets/icone-cadastro-ficha.png',
				descricao: 'Habilitação da Ficha Sanitária.',
				tipo: ETipoCard.CADASTRO,
			},
			{
				id: 19,
				titulo: 'ESTABELECIMENTO SIE E SISBI',
				routeLink: '/cadastroInstituicao',
				imagePath: 'assets/_icone-cadastro-instituicao.png',
				descricao: 'Requerimentos das indústrias no SIE e SISBI.',
				tipo: ETipoCard.CADASTRO,
			},
			{
				id: 20,
				titulo: 'FERRUGEM DA SOJA',
				routeLink: '/ferrugemSoja',
				imagePath: 'assets/icone-plantio.png',
				descricao: 'Notifique a ocorrência de Ferrugem Asiática da Soja.',
				tipo: ETipoCard.CADASTRO,
			},
			{
				id: 21,
				titulo: 'MAPA DAS NOTIFICAÇÕES DE FERRUGEM',
				routeLink: '/mapaFerrugemSoja',
				imagePath: 'assets/icone-mapa-ferrugem.png',
				descricao: 'Mapa de ocorrência de Ferrugem Asiática da Soja no Estado.',
				tipo: ETipoCard.CONSULTA,
			},
			{
				id: 22,
				titulo: 'FRUTÍFERAS',
				routeLink: '/frutiferas',
				imagePath: 'assets/icone-citrus-banana.png',
				descricao: 'Cadastro/Manutenção e Relatório Semestral de Vistoria/Monitoramento.',
				tipo: ETipoCard.CADASTRO,
			},
			{
				id: 23,
				titulo: 'RECONHECIMENTO DE AMOSTRAS',
				routeLink: '/reconhecimentoDeAmostra',
				imagePath: 'assets/icone-colaborador.png',
				descricao: 'Reconhecimento de amostras de sementes.',
				tipo: ETipoCard.CADASTRO,
			},
			{
				id: 24,
				titulo: 'ESTABELECIMENTO VEGETAL',
				routeLink: '/requerimentoVegetal',
				imagePath: 'assets/icon-predio-vegetal.png',
				descricao: 'Requerimentos dos estabelecimentos da divisão vegetal.',
				tipo: ETipoCard.CADASTRO,
			},
		].map(card => ({
			...card,
			ordenacao: card.titulo.toLowerCase()


		}));
		this.cards.orderBy('ordenacao');
	}

	get cardsList (): any[]{
		return this.cards.filter(({tipo}) => tipo == this.consulta || this.consulta == ETipoCard.TODOS);
	}
}
