<div class="container">
	<div class="row">
		<div class="col-md-6">	
		 <form id="filter">
			<input class="form-control" type="text"  name="titulo" placeholder="Pesquisar" [(ngModel)]="filtro"/>
		  </form>
		</div>
		<div class="col-md-6">	
			<form id="filter">
				<button type="button" class="btn btn-secondary" (click)="consulta = eTipoCard.CONSULTA"><strong>Consultas</strong></button>
				<button type="button" class="btn btn-secondary" (click)="consulta = eTipoCard.CADASTRO"><strong>Serviços</strong></button>
				<button type="button" class="btn btn-secondary" (click)="consulta = eTipoCard.TODOS"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-funnel-fill" viewBox="0 0 16 16">
					<path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z"/>
				  </svg></button>
			 </form>
		</div>
	<br>
	<br>
	<br>
	<div class="row">
		<div class="col-sm-6 col-md-6 col-lg-4 col-xl-3"  *ngFor="let card of cardsList | filter: filtro">
			<div class="card text-center mx-auto" role="button" routerLink={{card.routeLink}} >
				<div class="card-block">
					<div class="iconeCard" style="text-align: center">
						<img imagePathDirective src="{{card.imagePath}}" />
						</div>
							<h3 class="card-title"><strong>{{card.titulo}}</strong></h3>
							<p class="card-text">{{card.descricao}}</p>
						</div>
			</div>
		</div>
	</div>
</div>

