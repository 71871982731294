import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbProgressbarConfig } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-barraprogressao',
	templateUrl: './barraprogressao.component.html',
})
export class BarraprogressaoComponent {

	@Input() mensagem: string;
	@Input() valorMaximo: number = 1;
	@Input() valor: number = 0;

	constructor(private config: NgbProgressbarConfig, public activeModal: NgbActiveModal) {
		config.striped = true;
		config.animated = true;
		config.type = 'success';
		config.height = '20px';
	}

	get porcentagem() { return Math.trunc((this.valor * 100) / this.valorMaximo) }

	aumentarValor(): void { ++this.valor }

	fecharModal(): void {
		this.activeModal.dismiss('Close');
	}

}
