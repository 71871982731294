import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { Parametro } from "../model/app.parametro.model";

@Injectable()
export class RotasCadastro {

	readonly _baseUrlCadastro: string = `${environment.apiCadastroUrl}v1/`;

	get GerarCodigoAcessoRequerimentoEstabelecimento(): string { return `${this._baseUrlCadastro}CodigoAcesso/GerarCodigoAcesso/RequerimentoEstabelecimento`; }
	get GerarCodigoAcessoRequerimentoVegetal(): string { return `${this._baseUrlCadastro}CodigoAcesso/GerarCodigoAcesso/RequerimentoVegetal`; }
	get ValidarCodigoAcessoRequerimentoEstabelecimento(): string { return `${this._baseUrlCadastro}CodigoAcesso/ValidarCodigoAcesso/RequerimentoEstabelecimento?inscricao={0}&codigo={1}&tipoRequerimentoId={2}`; }
	get ValidarCadastroInstituicaoParaTipoRequerimento(): string { return `${this._baseUrlCadastro}Instituicao/ValidarCadastroInstituicaoParaTipoRequerimento/{0}?tipoRequerimento={1}`; }
	get UrlValidarCodigo(): string { return `${this._baseUrlCadastro}CodigoAcesso/ValidarCodigo`; }
	get ObterTipoConstrucaoInstituicao(): string { return `${this._baseUrlCadastro}Instituicao/TipoConstrucao`; }
	get ObterTipoAnexo(): string { return `${this._baseUrlCadastro}Anexo/TipoAnexo`; }
	get ObterUnidadeMedida(): string { return `${this._baseUrlCadastro}UnidadeMedida`; }
	get ObterGruposEspeciesAbatidas(): string { return `${this._baseUrlCadastro}Instituicao/GruposEspeciesAbatidas`; }
	get ObterEspeciesAbatidas(): string { return `${this._baseUrlCadastro}Instituicao/Grupo/{0}/EspeciesAbatidas`; }
	get ObterTiposAtivadadesInstituicaoPorTipoInstituicao(): string { return `${this._baseUrlCadastro}Instituicao/Atividades/{0}`; }
	get ObterInstituicaoCCE(): string { return `${this._baseUrlCadastro}Instituicao/ObterInstituicaoCCE`; }
	get ObterResponsavelTecnico(): string { return `${this._baseUrlCadastro}Colaborador/ResponsavelTecnico`; }
	get ObterPessoaPorCPF(): string { return `${this._baseUrlCadastro}Pessoa/ObterPessoa/{0}`; }
	get SalvarFerrugemSoja(): string { return `${this._baseUrlCadastro}FerrugemSoja` };
	get ObterFerrugemMapa(): string { return `${this._baseUrlCadastro}FerrugemSoja/MapaServicosPublicos` };
	get ObterFichaFerrugem(): string { return `${this._baseUrlCadastro}FerrugemSoja/FichaSanitaria` };
	get VerificarSePossuiNotificacao(): string { return `${this._baseUrlCadastro}FerrugemSoja/JaPossuiNotificacao` };
	get ObterFichasParaPlantio(): string { return `${this._baseUrlCadastro}Plantio/FichaSanitaria` };
	get JaPossuiPlantioParaSafra(): string { return `${this._baseUrlCadastro}PlantioFrutiferas/JaPossuiPlantioParaSafra` };
	get plantioSafra(): string { return `${this._baseUrlCadastro}Plantio/{0}/Safra` };
	get ObterFichaSanitariaPlantio(): string { return `${this._baseUrlCadastro}FichaSanitaria/{0}/ProdutorPropriedade` };
	get ObterCAR(): string { return `${this._baseUrlCadastro}Propriedade/CAR` };
	get ObterCARSiriema(): string { return `${this._baseUrlCadastro}Propriedade/CARSiriema` };
	get ObterReponsavelTecnicoPorCPF(): string { return `${this._baseUrlCadastro}Colaborador/ResponsavelTecnicoParaPlantio` };
	get ObterEnxertoVariedade(): string { return `${this._baseUrlCadastro}Enxertos/Variedade` };
	get ObterEnxertoCultivar(): string { return `${this._baseUrlCadastro}Enxertos/Cultivar` };
	get ObterPortaEnxerto(): string { return `${this._baseUrlCadastro}Enxertos/PortaEnxertos` };
	get ObterCultivar(): string { return `${this._baseUrlCadastro}Plantio/{0}/Cultivar` };
	get ObterUnidadesProducao(): string { return `${this._baseUrlCadastro}PlantioFrutiferas/UnidadesProducaoPorFicha` };
	get SalvarPlantioFrutiferas(): string { return `${this._baseUrlCadastro}PlantioFrutiferas/NovoPlantio` };
	get ObterPlantios(): string { return `${this._baseUrlCadastro}PlantioFrutiferas/Plantios` };	
	get RelatorioSemetral(): string { return `${this._baseUrlCadastro}PlantioFrutiferas/CadastrarRelatorioSemestral` };
	get PossuiCadastroCCE(): string { return `${this._baseUrlCadastro}Contribuinte/PossuiCadastroCCE?inscricao={0}` };

	adicionarParametros(urlBase: string, parametros: Parametro[]) {
		let parametrosUrl: string = urlBase + '?';

		for (let i = 0; i < parametros.length; i++) {
			parametrosUrl += parametros[i].nome + '=' + parametros[i].data;
			if (i !== parametros.length - 1) {
				parametrosUrl += '&';
			}
		}
		return parametrosUrl;
	}

	parseRota(urlBase: string, valores: any[]) {
		let rota = urlBase;
		for (let i = 0; i < valores.length; i++) {
			const local = '{' + i + '}';
			rota = rota.replace(local, valores[i]);
		}
		return rota;
	}
}
