import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

declare var GoogleAnalytics: any;

@Injectable({
    providedIn: 'root'
})

export class GoogleAnalyticsGuard implements CanActivate {
    constructor() { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return environment.production ? GoogleAnalytics() : true;
    }
}
