import { Injectable } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { resourceLimits } from "worker_threads";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { Data } from "../model/app.data.model";
import { ETipoInscricao } from "../constantes/app.etipoincricao.const";

@Injectable()
export class Utils {

	maskHandler(value) {
		value = value.split('_').join('').split('.').join('').split('-').join('');

		if (value.length > 11) {
			return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
		} else if (value.length <= 11) {
			return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
		}
		return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
	}

	maskData(value) {
		value = value.split('_').join('').split('.').join('').split('-').join('');
		return [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
	}

	testData(value) {
		const regexp = new RegExp(/[0-3][0-9][/][0-1][0-9][/][1-2][0-9][0-9][0-9]/);
		return regexp.test(value);
	}

	maskSegundoCoordenada = function (value) {
		value = value.split('_').join('').split('.').join('').split('-').join('');
		return [/\d/, /\d/, ',', /\d/, /\d/, /\d/, /\d/, /\d/];
	}

	maskTelefone = function (value) {
		var array = [];

		for (let index = 0; index < value.length; index++) {
			if (index == 9) break;
			array.push(/\d/);
		}
		return array;
	}

	getNameEnum = function (enumerador: any, value: any): string {
		let nomeEnum = Object.keys(enumerador).find(nomeEnum => enumerador[nomeEnum] == value);
		return nomeEnum;
	}

	somenteNumeros = function (value: any) {
		return value.toString().replace(/\D+/g, '');
	}

	public validarIE(ie: string, ehFichaSanitaria: boolean = true): boolean {
		const MIN_INSCRICAO = 280000000;
    	const MAX_INSCRICAO = 290000000;
    	const INSCRICAO_PADRAO_FICHA = 285000000;

		ie = ie.split('.').join("").split("-").join("").split("/").join("");
		if (!ie || ie.length !== 9) return false;

		const ieNumber = Number.parseInt(ie);
		if (isNaN(ieNumber) || ieNumber < MIN_INSCRICAO || ieNumber >= MAX_INSCRICAO) return false;
		if (ieNumber < INSCRICAO_PADRAO_FICHA && ehFichaSanitaria) return false;

		let soma = 0;
		let digito = 0;
		soma += Number.parseInt(ie.slice(0, 1)) * 9;
		soma += Number.parseInt(ie.slice(1, 2)) * 8;
		soma += Number.parseInt(ie.slice(2, 3)) * 7;
		soma += Number.parseInt(ie.slice(3, 4)) * 6;
		soma += Number.parseInt(ie.slice(4, 5)) * 5;
		soma += Number.parseInt(ie.slice(5, 6)) * 4;
		soma += Number.parseInt(ie.slice(6, 7)) * 3;
		soma += Number.parseInt(ie.slice(7, 8)) * 2;
		digito = 11 - (soma % 11)

		if (digito >= 10)
			digito = 0;

		if (digito != Number.parseInt(ie.slice(8, 9)))
			return false;
		else
			return true;
	}

	private _validarCPFCNPJ(cpfcnpj: string): boolean {
		if (cpfcnpj === null) return false;
		if (cpfcnpj === '') return false;

		if (cpfcnpj.length === 11)  // cpf
			return this.validarCpf(cpfcnpj);
		else if (cpfcnpj.length === 14) // cnpj
			return this.validarCnpj(cpfcnpj);

		return false;
	}

	public validarCpf(cpf: string): boolean {
		if (cpf == null) return false;
		if (cpf === '') return false;
		if (cpf.length !== 11) return false;

		let soma = 0;
		let resto = 0;

		for (let i = 1; i <= 9; i++)
			soma += Number(cpf.slice(i - 1, i)) * (11 - i);

		resto = (soma * 10) % 11;

		if (resto === 10 || resto === 11)
			resto = 0;

		if (resto !== Number(cpf.slice(9, 10)))
			return false;

		soma = 0;

		for (let j = 1; j <= 10; j++)
			soma += Number(cpf.slice(j - 1, j)) * (12 - j);

		resto = (soma * 10) % 11;

		if (resto === 10 || resto === 11)
			resto = 0;

		if (resto !== Number(cpf.slice(10, 11)))
			return false;

		return true;
	}

	public validarCnpj(cnpj: string): boolean {
		if (cnpj == null) return false;
		if (cnpj === '') return false;
		if (cnpj.length != 14) return false;

		let tamanho = cnpj.length - 2;
		let numeros = cnpj.slice(0, tamanho);
		const digitos = cnpj.slice(tamanho);
		let soma = 0;
		let pos = tamanho - 7;
		let resultado: number;

		for (let i = tamanho; i >= 1; i--) {
			soma += Number(numeros.charAt(tamanho - i)) * pos--;
			if (pos < 2)
				pos = 9;
		}

		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado !== Number(digitos.charAt(0)))
			return false;

		tamanho = tamanho + 1;
		numeros = cnpj.substring(0, tamanho);
		soma = 0;
		pos = tamanho - 7;

		for (let i = tamanho; i >= 1; i--) {
			soma += Number(numeros.charAt(tamanho - i)) * pos--;
			if (pos < 2)
				pos = 9;
		}

		resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

		if (resultado !== Number(digitos.charAt(1)))
			return false;

		return true;
	}

	public validarCpfCnpj(cpfcnpj: UntypedFormControl): any {
		if (cpfcnpj == null) return null;
		if (cpfcnpj.value == null) return null;

		const value = cpfcnpj.value.toString().replace(/\D+/g, '');
		if (!this._validarCPFCNPJ(value))
			return { cpfcnpjInvalido: true };
		else
			return null;
	}

	public ocultarCpfParcialmente(cpf: string): string {
		return this.somenteNumeros(cpf).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '***.$2.$3-**')
	}

	public ocultarCnpjParcialmente(cnpj: string): string {
		return this.somenteNumeros(cnpj)
			.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '**.$2.$3/$4-**')
	}

	public ocultarCpfCnpjParcialmente(cpfCnpj: string): string {
		if (!this._validarCPFCNPJ(cpfCnpj)) {
			return null;
		}
		return this.somenteNumeros(cpfCnpj).length === 11 ?
			this.ocultarCpfParcialmente(cpfCnpj)
			: this.ocultarCnpjParcialmente(cpfCnpj);
	}

	public validarEmail (email: string) {
		var emailPattern =  /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
		return emailPattern.test(email);
	}

	public validarSite (site: string): boolean {
		var sitePattern = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)$/igm;
		return sitePattern.test(site);
	}

	public ehValidoDDD(ddd:string){
		var codigosDDDValidos = [
			11, 12, 13, 14, 15, 16, 17, 18, 19,
			21, 22, 24, 27, 28, 31, 32, 33, 34,
			35, 37, 38, 41, 42, 43, 44, 45, 46,
			47, 48, 49, 51, 53, 54, 55, 61, 62,
			64, 63, 65, 66, 67, 68, 69, 71, 73,
			74, 75, 77, 79, 81, 82, 83, 84, 85,
			86, 87, 88, 89, 91, 92, 93, 94, 95,
			96, 97, 98, 99
		];
		return codigosDDDValidos.includes(parseInt(ddd));
	}

	public converterCoordenadaParaDecimal(coordenada: string): number {

		let coordenadaQuebrada = coordenada.split(" ");
		let tipoCoordenda = coordenada.replace(/[^A-Za-z]/g, "");
		let minutos = parseInt(coordenadaQuebrada[1].replace(/\D/g, ""));
		let segundos1 = parseInt(coordenadaQuebrada[2].replace(/[\'\"]/g, "")) * 60;
		let segundos2 = parseFloat(coordenadaQuebrada[3].replace(/[\'\"]/g, "").replace(",", "."));

		let sinal = tipoCoordenda == "S" || tipoCoordenda == "W";

		let numero = minutos + (segundos1 + segundos2) / 3600;

		return sinal ? numero * -1 : numero;
	}

	public ngbDataParaDataPTBR(data: NgbDate): string {
		return new Data(data).toPTBR();
	}

	verificaTipoInscricao(inscricao: string): ETipoInscricao{
		if(!inscricao)return null;

		if(this.validarCpf(inscricao))
			return ETipoInscricao.CPF
		if(this.validarCnpj(inscricao))
			return ETipoInscricao.CNPJ
		if(this.validarIE(inscricao))
			return ETipoInscricao.IE

		return null;
	}
}
