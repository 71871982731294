import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class Data implements NgbDateStruct {
	year: number;
	month: number;
	day: number;

	get dia() { return String(this.day || 0).padStart(2, '0') };
	get mes() { return String(this.month || 0).padStart(2, '0') };
	get ano() { return String(this.year || 0).padStart(2, '0') };

	constructor(data: any) {
		if (data) {
			const { year, month, day } = data;

			this.day = day;
			this.month = month;
			this.year = year;
		}
	}

	toPTBR(): string {
		return `${this.dia}/${this.mes}/${this.ano}`;
	}

	toISOString(): string {
		return `${this.ano}-${this.mes}-${this.dia}T00:00:00.000Z`;
	}
}
