
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Token } from './../model/app.token.model';
import { AuthenticationProvider } from '../auth/app.auth.token';

@Injectable()
export class AuthTokenResolver implements Resolve<Token> {

    constructor(private auth: AuthenticationProvider) { }

    resolve(): Observable<any> | Promise<any> | any {
        return this.auth.getToken();
    }
}