import { Component, isDevMode } from '@angular/core';
import { environment } from "src/environments/environment";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent {
	title = 'ServicosPublicos';
	isProdMode() {
		return environment.production;
	}
}

