import { Utils } from '../helper/app.services.utils';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[maskcpfcnpj]'
})
export class MaskCpfCnpjDirective {

  private element: HTMLInputElement;

  constructor(
	private el: ElementRef,
	private utils: Utils
  ) {
    this.element = el.nativeElement;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const somenteNumero = value.replace(/\D/g, '').substring(0, 14);

    let valorFormatado = '';
    if (this.utils.validarIE(somenteNumero, false)) {
      valorFormatado = somenteNumero; // Inscrição Estadual
    } else if (somenteNumero.length <= 11) {
      valorFormatado = this.formatCpf(somenteNumero);
    } else if (somenteNumero.length <= 14) {
      valorFormatado = this.formatCnpj(somenteNumero);
    }
    this.element.value = valorFormatado;
  }

  private formatCpf(cpf: string): string {
    return cpf
      .replace(/\D/g, '') // Remove tudo que não é dígito
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }

  private formatCnpj(cnpj: string): string {
    return cnpj
      .replace(/\D/g, '') // Remove tudo que não é dígito
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
  }

}
