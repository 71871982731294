import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { Parametro } from "../model/app.parametro.model";

@Injectable()
export class RotasCultura {

	readonly _baseUrlCultura: string = `${environment.apiCulturaUrl}v1/`;

	get ValidarCodigoAcesso(): string { return `${this._baseUrlCultura}CodigoAcesso/Amostrador?cpf={0}&protocolo={1}&codigo={2}`;}
	get ObterSolicitacao(): string { return `${this._baseUrlCultura}Solicitacao/{0}`;}

	get ReconhecimentoAmostra(): string { return `${this._baseUrlCultura}Solicitacao/{0}/Situacao/{1}`};
	
	adicionarParametros(urlBase: string, parametros: Parametro[]) {
		let parametrosUrl: string = urlBase + '?';

		for (let i = 0; i < parametros.length; i++) {
			parametrosUrl += parametros[i].nome + '=' + parametros[i].data;
			if (i !== parametros.length - 1) {
				parametrosUrl += '&';
			}
		}
		return parametrosUrl;
	}

	parseRota(urlBase: string, valores: any[]) {
		let rota = urlBase;
		for (let i = 0; i < valores.length; i++) {
			const local = '{' + i + '}';
			rota = rota.replace(local, valores[i]);
		}
		return rota;
	}
};
