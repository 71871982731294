import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { Parametro } from "../model/app.parametro.model";

@Injectable()
export class Rotas {

	baseUrl() {
		return environment.apiUrl + 'v1/';
	}
	baseUrlCadastro() {
		return environment.apiCadastroUrl + 'v1/';
	}
	pagamentoDaemsUrl() {
		return environment.pagamentoDaemsUrl;
	}

	agrotoxico() {
		return this.baseUrl() + 'Agrotoxico';
	}

	ingredienteAtivo() {
		return this.baseUrl() + 'AgrotoxicoIngredienteAtivo';
	}

	agrotoxicoBula() {
		return this.baseUrl() + 'Agrotoxico/{0}/bula';
	}

	agrotoxicoCultura() {
		return this.baseUrl() + 'AgrotoxicoCulturas';
	}

	agrotoxicoCulturaPraga() {
		return this.baseUrl() + 'AgrotoxicoCulturas/{0}/pragas';
	}

	agrotoxicoCulturaProduto() {
		return this.baseUrl() + 'AgrotoxicoCulturas/{0}/produtos';
	}

	agrotoxicoCulturaPragaProduto() {
		return this.baseUrl() + 'AgrotoxicoCulturas/{0}/pragas/{1}/Produtos';
	}

	agrotoxicoPragas() {
		return this.baseUrl() + 'AgrotoxicoPragas';
	}

	obterAtestadoInfluenzaImpressao(atestadoInfluenzaId: number) {
		return this.baseUrl() + 'AtestadoInfluenza/' + atestadoInfluenzaId;
	}

	agrotoxicoPragasProdutos() {
		return this.baseUrl() + 'AtestadoInfluenza/{0}/Produtos';
	}

	CARPorNumero() {
		return this.baseUrl() + 'Ficha/CARDaPropriedade';
	}

	CARsSiriema() {
		return this.baseUrl() + 'Ficha/CARSSiriema';
	}

	enderecoTipo() {
		return this.baseUrl() + 'Endereco/Tipo';
	}

	enderecoTipoTelefone() {
		return this.baseUrl() + 'Endereco/TipoTelefone';
	}

	enderecoTipoCoordenada() {
		return this.baseUrl() + 'Endereco/TipoCoordenada';
	}

	enderecoLatitudeGrauMS() {
		return this.baseUrl() + 'Endereco/LatitudeGrauMS';
	}

	enderecoLongitudeGrauMS() {
		return this.baseUrl() + 'Endereco/LongitudeGrauMS';
	}

	enderecoCepApi() {
		return this.baseUrl() + 'Endereco/EnderecoCepApi';
	}

	estabelecimento() {
		return this.baseUrl() + 'Estabelecimento';
	}
	estabelecimentoTipo() {
		return this.baseUrl() + 'Estabelecimento/Tipo';
	}
	estabelecimentoSubtipo() {
		return this.baseUrl() + 'Estabelecimento/Subtipo';
	}
	eventos() {
		return this.baseUrl() + 'Eventos';
	}
	eventosSituacoes() {
		return this.baseUrl() + 'Eventos/Situacoes';
	}
	municipios() {
		return this.baseUrl() + 'Municipios';
	}
	ufsMunicipios() {
		return this.baseUrl() + 'Municipios/UfsMunicipios';
	}
	municipiosComUF() {
		return this.baseUrl() + 'Municipios/MunicipiosComUF';
	}
	paises() {
		return this.baseUrl() + '';
	}
	tipoSexo() {
		return this.baseUrl() + '';
	}
	tipoEstadoCivil() {
		return this.baseUrl() + '';
	}
	tipoEndereco() {
		return this.baseUrl() + '';
	}
	tipoTelefone() {
		return this.baseUrl() + '';
	}
	tipoPropriedade() {
		return this.baseUrl() + '';
	}
	subTipoPropriedade() {
		return this.baseUrl() + 'Ficha/ObterSubTipoPropriedade';
	}
	ficha() {
		return this.baseUrl() + 'Ficha';
	}
	ObterTiposCoordenadas() {
		return this.baseUrl() + 'Ficha/ObterTiposCoordenadas';
	}
	ObterPaises() {
		return this.baseUrl() + 'Ficha/ObterPaises';
	}
	contribuinteCCE() {
		return this.baseUrl() + 'Ficha/ObterContribuinteCCE';
	}
	gerarCodigoAcesso() {
		return this.baseUrl() + 'Ficha/GerarCodigoAcesso';
	}
	gerarCodigoAcessoRequerimentoEstabelecimento() {
		return this.baseUrlCadastro() + 'CodigoAcesso/{0}/requerimento';
	}
	VerificaCoordenadasSiriema() {
		return this.baseUrl() + 'Ficha/verificaCoordenadasSiriema';
	}
	ValidarCodigoAcesso() {
		return this.baseUrl() + 'Ficha/ValidarCodigoAcesso';
	}
	ReenviarCodigoAcesso() {
		return this.baseUrl() + 'Ficha/ReenviarCodigoAcesso';
	}
	InserirFichaSanitaria() {
		return this.baseUrl() + 'Ficha/InserirFicha';
	}
	TransmitirFichaSanitariaDocumento() {
		return this.baseUrl() + 'Ficha/TransmitirFichaSanitariaDocumento';
	}
	TransmitirFichaSanitariaMarcaRebanho() {
		return this.baseUrl() + 'Ficha/TransmitirFichaSanitariaMarcaRebanho';
	}
	ObterFichaSanitariaMarcaRebanho() {
		return this.baseUrl() + 'Ficha/ObterFichaSanitariaMarcaRebanho';
	}
	ObterRelacaoFichaSanianitariaAnexo() {
		return this.baseUrl() + 'Ficha/ObterRelacaoFichaSanianitariaAnexo';
	}
	nucleos() {
		return this.baseUrl() + 'Nucleo/NucleosDoMS';
	}
	laboratorio() {
		return this.baseUrl() + 'Laboratorio';
	}
	autenticidadeGta() {
		return this.baseUrl() + 'AutenticidadeGta';
	}
	autenticidadeGtaDownload() {
		return this.baseUrl() + 'AutenticidadeGta/{0}';
	}
	autenticidadeGtaSaniagroDownload() {
		return this.baseUrl() + 'AutenticidadeGta/{0}/Saniagro';
	}
	relatorioSaldoGeral() {
		return this.baseUrl() + 'relatorio/saldogeral/{0}';
	}
	relatorioDocumentosDeTransito() {
		return this.baseUrl() + 'relatorio/DocumentosDeTransitoRel?especieAnimalID={0}&periodoInicial={1}&periodoFinal={2}&municipioIDOrigem={3}&municipioIDDestino={4}&municipioUFDestino={5}&finalidadeID={6}';
	}
	tiposVacina() {
		return this.baseUrl() + 'TiposVacina';
	}
	tiposVacinaEspecificado() {
		return this.baseUrl() + 'TiposVacina/{0}/especificado';
	}
	tipoCoordenadaFicha() {
		return this.baseUrl() + 'Endereco/TipoCoordenada';
	}
	vacina() {
		return this.baseUrl() + 'Vacina';
	}
	colaborador() {
		return this.baseUrl() + 'Colaborador';
	}
	reponsavelTecnico() {
		return this.baseUrl() + 'Colaborador/ResponsavelTecnico';
	}
	reponsavelTecnicoNoCREAPorCPF() {
		return this.baseUrl() + 'CREA/ResponsavelTecnicoNoCREAPorCPF';
	}
	campanhaMesAno() {
		return this.baseUrl() + 'relatorio/CampanhaMesAno?mes={0}&ano={1}';
	}
	campanhaMes() {
		return this.baseUrl() + 'relatorio/CampanhaMes';
	}
	campanhaAno() {
		return this.baseUrl() + 'relatorio/CampanhaAno';
	}
	campanhaMesAnoRel() {
		return this.baseUrl() + 'relatorio/CampanhaMesAnoRel?mes={0}&ano={1}&campanhaID={2}';
	}
	coordenadasSiriema() {
		return this.baseUrl() + 'Ficha/verificaCoordenadasSiriema';
	}
	obterHistoricoHabilitacaoFicha() {
		return this.baseUrl() + 'Ficha/ObterHistoricoHabilitacao'
	}
	plantioCultura() {
		return this.baseUrl() + 'PlantioCulturas';
	}
	plantioCultivarComprovante() {
		return this.baseUrl() + 'PlantioCulturas/{0}/Comprovante';
	}
	plantioCultivar() {
		return this.baseUrl() + 'PlantioCulturas/{0}/Cultivar';
	}
	plantio() {
		return this.baseUrl() + 'PlantioCulturas/Plantio';
	}
	enviarEmailComprovantePlantio() {
		return this.baseUrl() + 'PlantioCulturas/EnviarEmailComprovantePlantio';
	}
	enviarEmailComprovanteFrutiferas() {
		return this.baseUrl() + 'PlantioCulturas/ComprovantePlantioFrutiferas';
	}
	animalIdentificado() {
		return this.baseUrl() + 'Animal/AutenticidadeAnimal';
	}
	animalEspecie() {
		return this.baseUrl() + 'Animal/Especies';
	}
	animalIdentificadoCertificadoDownload() {
		return this.baseUrl() + 'Animal/AutenticidadeAnimal/{0}';
	}
	animalIdentificadoCertificadoComMarcaDAguaDownload() {
		return this.baseUrl() + 'Animal/{0}/publico';
	}
	finalidades() {
		return this.baseUrl() + 'Gta/Finalidades';
	}
	ptv() {
		return this.baseUrl() + 'Ptv';
	}
	laudoExamesRequisicao() {
		return this.baseUrl() + 'LaudoExame/Requisicao';
	}
	certificadoRequisicaoExameDownload() {
		return this.baseUrl() + 'LaudoExame/CertificadoRequisicao/{0}';
	}
	laudoExame() {
		return this.baseUrl() + 'LaudoExame/{0}';
	}
	obterRequisicaoAnimalParaImpressao() {
		return this.baseUrl() + 'Requisicao/ObterRequisicaoParaImpressaoPublico';
	}
	ptvDownload() {
		return this.baseUrl() + 'Ptv/VisualizarPTV?id={0}&inscricaoOrigem={1}&numero={2}';
	}
	ecise() {
		return this.baseUrl() + 'ECISE';
	}
	eciseDownload() {
		return this.baseUrl() + 'ECISE/{0}';
	}

	pessoa() {
		return this.baseUrl() + 'Pessoa/ObterDadosPessoa';
	}

	servicos() {
		return this.baseUrl() + 'ServicoFinanceiro/ServicoPublico';
	}

	guia() {
		return this.baseUrl() + 'Guia';
	}

	guiaRequerimento():string {
		return this.baseUrl() + 'Guia/GerarGuiaRequerimento';
	}

	imprimirGuia() {
		return this.baseUrl() + 'Guia/cobranca/{0}';
	}

	uferms() {
		return this.baseUrl() + 'UFERMS';
	}

	obterDocumentoManutencaoPlantio() {
		return this.baseUrl() + 'PlantioCulturas/DocumentoManutencaoUP'
	}

	adicionarParametros(urlBase: string, parametros: Parametro[]) {
		let parametrosUrl: string = urlBase + '?';

		for (let i = 0; i < parametros.length; i++) {
			parametrosUrl += parametros[i].nome + '=' + parametros[i].data;
			if (i !== parametros.length - 1) {
				parametrosUrl += '&';
			}
		}
		return parametrosUrl;
	}

	parseRota(urlBase: string, valores: any[]) {
		let rota = urlBase;
		for (let i = 0; i < valores.length; i++) {
			const local = '{' + i + '}';
			rota = rota.replace(local, valores[i]);
		}
		return rota;
	}
};
