export class Paginacao<TResult> {
    data: TResult[];
    paginaAtual: number;
    totalRegistros: number;

    constructor(data: TResult[], paginaAtual: number, totalRegistros: number) {
		this.data = data;
		this.paginaAtual = paginaAtual;
		this.totalRegistros = totalRegistros;
	}
}
