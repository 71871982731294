import { Injectable } from "@angular/core";
import { BlockUIService } from "ng-block-ui";

@Injectable()
export class BlockUtil {
	blockName: string;

	constructor(private blockUIService: BlockUIService) { }

	bloquear(): void {
		this.blockUIService.start(this.blockName);
	}

	desbloquear(): void {
		this.blockUIService.stop(this.blockName);
	}
}
