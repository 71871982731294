<div class="agrupaTopo">
  <nav class="navbar navbar-expand-lg navbar-light navbar-fixed-top">
    <div class="container">
      <div class="navbar-header">
        <a class="navbar-brand" href="">
          <img imagePathDirective src="assets/logo-iagro-topo.png" class="d-inline-block align-top my-1" width="140"
            height="70" alt="">
        </a>
        <button class="navbar-toggler navbar-toggler-right" (click)="isNavbarCollapsed = !isNavbarCollapsed"
          type="button" data-toggle="collapse" data-target="#navCollapse">
          <span class="navbar-toggler-icon"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>
      <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse flex-row-reverse" id="navCollapse">
        <ul class="navbar-nav nav">
          <li class="nav-item">
            <a class="nav-link" target="_blank" href={{esaniagroUrl}}>e-Saniagro</a>
          </li>
          <li class="divider">/</li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" href={{ciadeUrl}}>CIADE</a>
          </li>
          <li class="divider">/</li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" href="{{consultaDaemsUrl}}">DAEMS</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="jumbotron">
    <div class="container">
      <div class="iconeTopo"></div>
      <div class="descricaoTopo">
        <h1 class="display-3">{{descricaoTopo}}</h1>
        <p>{{descricaoMeio}}</p>
      </div>
    </div>
  </div>
</div>
