import { NgModule, ModuleWithProviders } from "@angular/core";
import { DatexPipe } from "src/app/helper/app.services.datexPipe";
import { AuthenticationProvider } from "src/app/auth/app.auth.token";
import { DirectiveModule } from 'src/app/directive/app.directive.module';
import { CommonModule } from '@angular/common';
import { BarraprogressaoComponent } from "./modal/barraprogressao/barraprogressao.component";
import { NgbProgressbar } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
	declarations: [DatexPipe, BarraprogressaoComponent],
	imports: [
		DirectiveModule,
		CommonModule,
		NgbProgressbar
	],
	exports: [DatexPipe, DirectiveModule, BarraprogressaoComponent]
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [AuthenticationProvider]
		}
	}
}
