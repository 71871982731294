export const environment = {
	tokenUrl: "https://hom.id.ms.gov.br/auth/realms/ms/protocol/openid-connect/token",
	clientSecret: "07537fa2-d37d-417f-858b-752ea3cbcbae",
	clientId: "esaniagro",
	production: false,
	apiUrl: "https://hom.api.ms.gov.br/api-esaniagro/",
	apiCadastroUrl: "https://hom.gw.sgi.ms.gov.br/d0485/esaniagrocadastro/",
	apiConsultaUrl: "https://hom.gw.sgi.ms.gov.br/k1049/esaniagro-consultas/",
	apiCulturaUrl: "https://hom.gw.sgi.ms.gov.br/k1125/esaniagro-cultura/",	
	apiRequerimentoUrl: "https://hom.gw.sgi.ms.gov.br/k1248/esaniagro-requerimento/",
	chave: "chave=123456",
	pessoa: "pessoa=03980919000187",
	auth: true,
	esaniagroUrl: "http://hom.gap.ms.gov.br/",
	ciadeUrl: "http://hom1.servicos.iagro.ms.gov.br/ciade/publish.htm",
	consultaDaemsUrl: "https://servicos.efazenda.ms.gov.br/daemsabertopublico/consultadaems",
	pagamentoDaemsUrl: "https://hom.servicos.efazenda.ms.gov.br/daemsabertopublico/PagamentoDAEMS",
	chavePrivada: `-----BEGIN RSA PRIVATE KEY-----
	MIIFxAIBAAKCAUEAlsIVfjep1vc/v2TaG7by3lYqdpEHOtbL02y9CPj6m8sZ+LTG
	U+cdl/ofAfgp9dykI2AdT2jBhGawpE25+kKN2i5c7fQVtx2zKHf/Kpta5hEpriDY
	LHbgFFT+Mo4kZ/xUQsDAAns8zpbnhD+GUQhAdFHRiyILxazdAyBn3son4Ze+Bs4y
	BptVcQTyU/ptJpT2kfvKDlCPraquS3fw7YRKD/mPKg2gse1s8k14dg2Ye+JIzFdc
	So/NQpTp91NckQoEgWDJZ6f7ytUflXz+ci/Q+/TO76/jjrmHyMqmEhBo+zyPrUNA
	udPuMeryo3XF4uBb/iZTzO2b1B8TUCpbBaGbAPQa/Fy7c1ccfEfg0JnPWFlcnPql
	rxPnUx0pVTyapNJxFgnWEDCOD4ngBuk4i+YrOsX64a09ZnnwawzUAvXxhKECAwEA
	AQKCAUEAj0N690ngXUbyzmgBmMq/e6f4jFn/u9t5ML6p2Uf5p2lXkfAAdKUxi0YG
	h3GUNm9pj7B7ygWruK15eXnfnUH32Xmrl/kXVb+kjHWUB6JAvFdc+3t80qxY/xlR
	wysuz56LWDAhh3oja9MdyaAwfPrRfXhvH7/H3h8KnDkEXERRp+DylTcHdR3SESf5
	PPLDHSH8OvhCqF3IW+nR3ifpzVykn5pGe1MgEt0Y7/vmYsp16V0zHivDb+49maF4
	C4QlaKPaiV2JlGqpU8j5dSnqn07dS7FNivcTWZTx5W0Nq+uc8ERVmQg6um4zA2jJ
	5u6hK3f1zbswUPVNddkQWiIhKeL/g+hlQCiNZwTDgWaKUb134NofuCncmS/KT0RG
	Ak7UWvjpWCYhUneaCJg6sS0/X+Uzd9RH9NkIv5YEllMJWDJXGg0CgaEAwbNU4uwB
	TYVParx01U8hOf2JmWyqklwO/pYeW4vf+JIr+ETdZ7Z566xsPo25cBKSlotX4KLy
	LqgUsGHq9Ru8anYU/EbJG76R0wm8PksQJfkdTLAekBkdentP7cB9iLNWM18xTE/4
	AwX1/6My/oL+8slUmuQOheE72QWwK4wEAgL2ZdnLoQ+dblMx3KW512xp7h3g0PhF
	WRmJPQPi18XvuwKBoQDHPwR1y6GBYG+ztElU5Jn998dA9PMJVf2EV1ZiPtexmsrv
	tqS/H3fxvnDdRKXI/GReCoVv4v3Jzd3LxFX2IoZs0z7hzgIgvWIEVHw5vG1InR4e
	Ig4G9zavF1tYQhKmgdIXTxqyxoPylHARlHdLD+oxu2eKtmFrYuos4XVsDa3xL4to
	m1fSozBiGRpBG6W3A0KbfzJBu2Y3CifNqyzGhjFTAoGgPV6wGmVj1EfUbaDf82Vq
	xZDKs812DYEVTxwI6kBa6cqGG9bVvzJVVpH+iaMliZiY0RG8+fMmgBnI1Elxw87R
	13kqFPPoa93pHdAL0EDAEQfofI+gX09Vw6SmIlHGdDU9rnEOkXsUvQB6fFAcq/6d
	ABdBswFmiIxgBdaDhoiKDIkbwBFNLu5flsDNH3dydgpnVYYp4IuIjT5F+QruKCcS
	WQKBoFlzul3kSSMbl6q3Y0DgzR4PUv6fuklTuNjkmRzMl+vd0gRjHH3N8/ytgmBk
	xIWCgtERqiy8XTJv4YbtI6a4g19yS8X2xk/KlqnZAFivduTl3TKsGV4ZOQzM0K7E
	IzlsCVV7p0HiDDUpXVMXM6OYho1DVM4a6YvbwcTah+w2DAS/UrhpHbmsmDlCid33
	OqP6q7nCFl8acl59ibc1hYmvj48CgaEAkrcUXcGDgbuz9kNqcRo7tnlyqi3UCuRP
	ke4JkVCKYLPkuoywHmdM/GRgQC9bsc3uNpjJdNbIA7MWlxOmctaE5+1w0Z7C6Wrg
	YMlt3q4muRR0PDHmfkS8QukHSdIQ/ObYj/JUlR+noSTuThAQiJwlp8oYRhC5pfwR
	xzoT/KduIgdl2V+eRjdlodFFVtfsc1EdBQg5ouk/9gkqhChlQvwXyg==
	-----END RSA PRIVATE KEY-----`
};
